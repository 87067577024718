@font-face {
  font-family: 'Avenir';
  font-style: normal;
  /* This will allow to use all font-style */
  font-weight: normal;
  /* This will allow to use all font-weight */
  src:
    url(./Assets/Font/Avenir_TTF/Avenir-Heavy-05.ttf) format('truetype'),
}

html {
  scroll-behavior: smooth;
  --blackColor: #1C1C1E;
  --darkBlackColor: #161616;
  --whiteColor: #fff;
  --darkGolderColor: #665837;
  --layoutTheme: #191919;
  --layoutSiderMenu: #23211e;
  --tomatoRed: #ff4444;
  --parrotGreen: #00D539;

  --defaultThemeColor: #27498C;
  --defaultFontsFamilyPopins: "Poppins", sans-serif;
  --defaultColorOne: #27498C;
  --defaultColorTwo: #3BBBD0;
  --defaultGradientColor: linear-gradient(to left, var(--defaultColorTwo), var(--defaultColorOne));
  --defaultGradientColorReverse: linear-gradient(to left, var(--defaultColorOne), var(--defaultColorTwo));
}

*,
body {
  box-sizing: border-box;
  font-family: 'Avenir', sans-serif !important;
  font-weight: 200 !important;
  font-size: 12px;

}

.ant-layout-content {
  color: #000 !important;
}

.jodit-add-new-line {
  display: none;
}

/* Side Scroller */
::-webkit-scrollbar {
  height: 9px;
  width: 8px
}

::-webkit-scrollbar-track {
  background: #d9dcd7
}

::-webkit-scrollbar-thumb {
  background: var(--blackColor);
  border-radius: 3px
}

::-webkit-scrollbar-thumb:hover {
  background: #337ec3
}

/* =========================================================== ANTD GLOBAL =========================================================== */
.ant-layout {
  font-family: 'Avenir', sans-serif !important;
  font-weight: 600;
}

li.ant-menu-item.ant-menu-item-only-child {
  /* background-color: #f1f1f1; */
  background-color: #3bbcd00a;
  border-radius: 0 !important;
  margin: 0 0 3px !important;
  padding: 1.5rem;
}

li.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child {
  /* background-color: #f1f1f1; */
  background: var(--defaultGradientColorReverse);
  border-radius: 0 !important;
  color: var(--whiteColor);
  margin: 0 0 3px !important;
  padding: 1.5rem;
}

.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child .sideIcon {
  color: var(--whiteColor) !important;
}

.headingStyle {
  opacity: 0;
  animation: fadeIn 3.5s ease-out forwards;
  -webkit-animation: fadeIn 3.5s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

header.ant-layout-header.css-dev-only-do-not-override-1rqnfsa {
  background: var(--whiteColor);
}


li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  color: var(--whiteColor) !important;
  background: var(--defaultGradientColorReverse);
  font-weight: bolder !important;
  /* border-left: 7px solid var(--defaultColorTwo); */
}

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child .sideIcon {
  color: var(--whiteColor) !important;
}

.sideIcon {
  color: var(--defaultColorOne);
  font-size: 20px;
}

.ant-layout .ant-layout-sider {
  /* background: var(--defaultGradientColor); */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}

.ant-menu-inline .ant-menu-item {
  height: 40px;
  line-height: 25px;
  list-style-position: inside;
  list-style-type: disc;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.ant-dropdown-menu-item {
  color: #fff !important;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu .ant-menu-title-content {
  transition: color 0.3s;
  align-items: center;
  display: flex;
}

.ant-layout-sider-trigger {
  display: none;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  border: 1px dashed var(--defaultGradientColor);
}

.ant-table-thead .ant-table-cell {
  /* background-color: var(--defaultColorTwo) !important; */
  background-color: #3bbcd044 !important;
  color: var(--blackColor) !important;
}

.jodit-container:not(.jodit_inline) .jodit-workplace,
.jodit-container:not(.jodit_inline) {
  border-radius: 3px;
}

.jodit-container:not(.jodit_inline) {
  border: 1px solid var(--defaultGradientColor) !important;
  border-radius: 3px;
}

.jodit-status-bar {
  display: none !important;
}

.ant-breadcrumb-separator {
  font-size: 1.2rem;
}

.ant-input::placeholder {
  color: lightgray !important;
}

span.ant-select-selection-item {
  display: flex;
  text-align: center;
  align-items: center;
  /* color: var(--whiteColor); */
}


.ant-picker-outlined.ant-picker-status-error:not(.ant-picker-disabled) {
  border-width: 1px;
  border-style: solid;
  border-color: #ff4d4f;
}

.ant-input-affix-wrapper>input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
}

.ant-picker-outlined {
  border-width: 1px;
  border-style: solid;
  border-color: var(--defaultThemeColor);
}

.ant-select-selector {
  /* border: 1px solid var(--defaultThemeColor) !important; */
  min-height: 3rem;
}

.ant-select-single {
  min-height: 2.5rem;
  text-align: left;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  /* width: 8rem !important; */
  /* height: 8rem !important; */
  margin-inline-end: 8px;
  /* margin-bottom: 8px; */
  text-align: center;
  vertical-align: top;
  border: 1px dashed var(--defaultThemeColor);
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
  /* width: 8rem !important;
  height: 8rem !important; */
  border-color: 1px solid var(--defaultThemeColor) !important;
}

.ant-picker-outlined.ant-picker-status-error:not(.ant-picker-disabled):hover {
  border: 1px solid var(--defaultThemeColor) !important;
}

.ant-picker-outlined:hover {
  border: 1px solid var(--defaultThemeColor) !important;
}


.ant-switch {
  background-color: var(--defaultColorTwo) !important;
}

.ant-table-wrapper .ant-table-cell-fix-left {
  background: inherit;
}

.ant-switch.ant-switch-checked {
  background: var(--defaultColorOne) !important;
}

.ant-picker.ant-picker-range {
  width: 100%;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  color: var(--whiteColor);
  background: var(--defaultGradientColor);
  border-color: var(--defaultGradientColor);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.ant-divider-horizontal {
  margin: 24px 0;
  background: var(--defaultGradientColor);
}

.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  padding: 4px;
  list-style-type: none;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  margin-bottom: 3px !important;
  /* color: var(--whiteColor) !important; */
  font-size: 1rem;
  color: var(--blackColor) !important;
  /* background: var(--defaultGradientColorReverse) !important; */
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  margin-bottom: 3px !important;
  color: var(--whiteColor) !important;
  font-size: 1rem;
  background: var(--defaultGradientColorReverse) !important;
}

.ant-dropdown {
  min-width: 132.507px !important;
}

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding-inline: 15px;
  padding-block: 0;
  font-size: 14px;
  line-height: 30px;
  background: transparent !important;
  border: transparent !important;
}

.ant-tooltip.css-dev-only-do-not-override-1rqnfsa.ant-tooltip-placement-top {
  display: none;
}

.highcharts-button {
  display: none
}

.ant-upload-list-item .ant-upload-list-item-error {
  border-color: 1px solid var(--defaultThemeColor) !important;

}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-error {
  border-color: var(--defaultGradientColor) !important;
  border-color: 1px solid var(--defaultThemeColor) !important;

}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background: var(--defaultGradientColor) !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--defaultGradientColor) !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--defaultGradientColor) !important;
}


.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: var(--defaultThemeColor) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: var(--defaultGradientColor) !important;
  border-color: var(--defaultGradientColor);
}

.ant-checkbox .ant-checkbox-inner {
  border: 1px solid var(--defaultGradientColor);
}

.award.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {

  border: none !important;
}

/* Table */
.ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper .ant-table-thead>tr>td {
  border-bottom: none !important;
}

.ant-table-wrapper .ant-table {
  background: transparent;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

/* Chart */
text.highcharts-credits {
  display: none;
}

/* Jodit Editor */
.jodit-toolbar__box:not(:empty) {
  border-bottom: transparent !important;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

g.highcharts-legend-item.highcharts-area-series.highcharts-color-undefined.highcharts-series-0 {
  display: none;
}

g.highcharts-legend-item.highcharts-map-series.highcharts-color-undefined.highcharts-series-0 {
  display: none;
}

.ant-layout .ant-layout-header {
  background-color: var(--whiteColor);
  box-shadow: rgba(0, 0, 0, 0.10) 0px 3px 8px;
}

.ant-steps-item-active .ant-steps-item-title {
  color: var(--defaultThemeColor) !important;
}

/* ANIMATION */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 700ms ease-in;
  -webkit-transition: opacity 700ms ease-in;
  -moz-transition: opacity 700ms ease-in;
  -ms-transition: opacity 700ms ease-in;
  -o-transition: opacity 700ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 700ms ease-out;
  -webkit-transition: opacity 700ms ease-out;
  -moz-transition: opacity 700ms ease-out;
  -ms-transition: opacity 700ms ease-out;
  -o-transition: opacity 700ms ease-out;
}

.input__ {
  background-color: #fbfcff;
  background-color: var(--defaultwhitecolor);
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2);
  height: 3rem;
}

.input__textarea {
  background-color: #fbfcff;
  background-color: var(--defaultwhitecolor);
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2);
}

.link_style {
  color: #000;
  text-decoration: none;
}

.avtar {
  border: 1px solid var(--defaultColorTwo);
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .25);
  font-style: italic;
  height: 41px;
  padding: 1.5rem;
  align-items: center;
  display: flex;
}

.toggling_sider {
  box-shadow: 0 5px 15px rgba(0, 0, 0, .35);
  min-width: 250px !important;
}

.color_breadcrumb {
  color: var(--defaultColorOne) !important;
}

.commonButton.activecolor {
  background: rgba(128, 128, 128, 0.266) !important;
  cursor: auto !important;
}

.jodit-add-new-line {
  display: none !important;
}

.commonButton {
  background: var(--defaultGradientColorReverse);
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  min-width: 10rem;
  border-radius: 20rem;
  text-transform: uppercase;
  -webkit-border-radius: 20rem;
  -moz-border-radius: 20rem;
  -ms-border-radius: 20rem;
  -o-border-radius: 20rem;
}

.commonButtonDelete {
  background: var(--defaultGradientColorReverse);
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  min-width: 6rem;
  text-transform: uppercase;
}

.commonButtonEdit {
  border: 2px solid var(--defaultColorOne);
  color: var(--defaultColorOne);
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  min-width: 6rem;
  text-transform: uppercase;
}

.changePassword {
  color: var(--defaultColorOne);
  border: 3px solid var(--defaultColorOne);
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  min-width: 15rem;
  outline: auto;
  text-transform: uppercase;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* align-items: center; */
}

.searchField>input {
  background-color: transparent !important;
  outline: none;
  border: none;
  padding-inline: 1rem;
}

.searchField {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.delete_button {
  cursor: pointer;
  color: var(--defaultColorOne);
  font-size: 1.3rem;
}

.view_button {
  cursor: pointer;
  color: var(--defaultColorTwo);
  font-size: 1.3rem;
}

.commonBoxShadow {
  box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 15px;
}

.my_upload .ant-tooltip-inner {
  display: none;
}

.customSiderWidth {
  width: 255px !important;
  min-width: 255px !important;
  max-width: 255px !important;
  flex: 0 0 255px !important;
}
.sd .headingStylee p{
  word-break: break-all !important;
}

/* Dahboard animation */
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);
  margin: 0 15px 30px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color: #fff;
  color: #000;
  overflow: hidden;
  position: relative;
}

.ant-image .ant-image-img {
  width: auto !important;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #FFF;
}

.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}

/* .about .anticon.anticon-eye{
display: none;
} */
.ag-courses-item_title {
  min-height: 70px;
  margin: 0 0 25px;
  overflow: hidden;
  font-weight: bold;
  font-size: 30px;
  color: #000;
  z-index: 2;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
}

.ag-courses-item_date-box {
  font-size: 18px;
  color: #000;
  z-index: 2;
  position: relative;
}

.ag-courses-item_date {
  font-weight: bold;
  color: #000;
  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease
}

.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background: var(--defaultGradientColor);
  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;
  border-radius: 50%;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

@media screen and (max-width: 992px) {
  form.ant-form.ant-form-vertical.css-dev-only-do-not-override-1rqnfsa {
    width: 90%;
  }

  li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    padding-left: 11px !important;
  }

  li.ant-menu-item.ant-menu-item-only-child {
    padding: 0 0 0 13px !important;
  }

  .customSiderWidth {
    width: 65px !important;
    min-width: 65px !important;
    max-width: 65px !important;
    flex: 0 0 65px !important;
  }

  .commonButton {
    min-width: 10rem !important;
  }

}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.resources {
  background-color: var(--whiteColor);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

span.jodit-placeholder {
  display: none !important;
}

@media screen and (max-width: 992px) {
  .ant-layout-sider {
    display: none !important;
  }
}

.error .ant-upload-list-item.ant-upload-list-item-error {
  color: #000 !important;
  margin-top: 0 !important;
}

.error .ant-upload-list-item.ant-upload-list-item-done {
  color: #000 !important;
  margin-top: 0 !important;
}

.modall_view .ant-modal-content {
  box-shadow: none;
  padding: 7px 7px !important;
  border-radius: 5px;
  background-color: transparent;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.img-thumbnail.css {
  border: none !important;
}

.selectarrow .ant-select-arrow {
  margin-top: 0 !important;
  color: var(--defaultColorOne);
}

/* .ant-pagination .ant-select-selector{
  display: none !important;
} */
.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  align-items: baseline !important;
  margin-right: 1rem;
}

.ant-pagination-options .ant-select-arrow {
  margin-top: 0 !important;
}

.title_recommend {
  font-size: 0.7rem !important;
}

.jodit-ui-block__className{
  display: none!important;
}

.jodit-toolbar-button_link .jodit-toolbar-button__trigger{
  display: none!important;
}

.ant-check .ant-checkbox .ant-checkbox-input{
  opacity: 1;
}

.ant-check .ant-radio-group span{
  opacity: 1;
}

.commonButton:hover{
  background: var(--defaultGradientColorReverse)!important;
  color: white!important;
}

.question-img .ant-upload-select{
  height: 50px!important;
  width: 50px!important;
}
.ant-table-placeholder{
  z-index: 999!important;
}

.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item{
  color: #000!important;
}